import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Grid, Typography, makeStyles,
} from '@material-ui/core';
import {
  TextValue, generateKey, getScoreBarScale, useQuery,
} from '../common/Utility';
import ReportScoreRow from './ReportScoreRow';
import ScoreBar from './ScoreBar';

const useStyles = makeStyles(() => ({
  typography: { fontWeight: 500 },
  text: { textAlign: 'justify' },
}));

function CategoryScore({ open, onClickOpen, configuration }) {
  const classes = useStyles();

  return (
    <>
      <ReportScoreRow
        buttonOnClick={
          configuration.description || configuration.score_description
            ? () => onClickOpen(configuration.name) : undefined
        }
        buttonText={!open ? 'More info' : 'Close'}
        subScores={configuration.sub_scores}
        maxScore={configuration.max_score}
        reportName={configuration.name}
        score={configuration.score}
        key={configuration.name}
      />

      {open && (
        <Grid container justifyContent="center" spacing={3} style={{ padding: 0 }}>
          {configuration.description && (
            <>
              <Grid item sm={12} md={2}>
                <Typography className={classes.typography}>Description:</Typography>
              </Grid>
              <Grid item sm={12} md={10}>
                <TextValue className={classes.text}>{configuration.description}</TextValue>
              </Grid>
            </>
          )}
          {configuration.score_description && (
            <>
              <Grid item sm={12} md={2}>
                <Typography
                  style={{
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                  className={classes.typography}
                >
                  Result:
                </Typography>
              </Grid>
              <Grid item sm={12} md={10}>
                <TextValue
                  style={{
                    wordWrap: 'break-word',
                    width: '100%',
                  }}
                  className={classes.text}
                >
                  {configuration.score_description}
                </TextValue>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
}

CategoryScore.propTypes = {
  configuration: PropTypes.object.isRequired,
  onClickOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function CategoryScores({ configuration }) {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  const [open, setOpen] = useState({});
  const openCloseAllMoreInfoButton = Object.values(open).filter(value => value).length > 1;

  const topLevelCategory = query.get('top-level-category');

  return (
    <Grid container justifyContent="center">
      <Grid className="pt-4" item xs={10}>
        <ScoreBar
          scale={getScoreBarScale(configuration.max_score)}
          title={`Your ${configuration.name} Score`}
          maxScore={configuration.max_score}
          score={configuration.score}
        />
      </Grid>

      <Grid item xs={9}>
        {configuration.sub_categories.sort((a, b) => b.score - a.score).map(report => (
          <CategoryScore
            onClickOpen={name => {
              const openCopy = { ...open };
              openCopy[name] = !openCopy[name];

              setOpen(openCopy);
            }}
            open={open[report.name] || false}
            key={generateKey(report.name)}
            configuration={report}
          />
        ))}
      </Grid>

      <Grid className="py-4" item xs={10}>
        <Button
          onClick={() => history.push(`${location.pathname}#tab=${query.get('tab')}&top-level-category=${topLevelCategory}`)}
          variant="contained"
          className="button"
          color="primary"
        >
          Back To
          {' '}
          {topLevelCategory}
          {' '}
          Report
        </Button>

        {openCloseAllMoreInfoButton && (
          <Button
            onClick={() => {
              const openCopy = { ...open };

              Object.keys(openCopy).forEach(name => {
                if (openCopy[name]) openCopy[name] = false;
              });

              setOpen(openCopy);
            }}
            style={{ marginLeft: '16px' }}
            variant="outlined"
            className="button"
            color="primary"
          >
            Close All More Info
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

CategoryScores.propTypes = {
  configuration: PropTypes.object.isRequired,
};

export default CategoryScores;
