import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { MenuItem } from '@material-ui/core';
import CreateTaskDialog from './CreateTaskDialog';
import { ENTITY_TYPE_FILE, TASK_CONVERTER_GITHUB_DOWNLOAD, TASK_CONVERTER_GITHUB_UPLOAD } from '../../core/entities';
import {
  createGitHubDownloadTask,
  createGitHubUploadTask,
  fetchGitHubRepositories,
  fetchGitHubBranches,
  fetchGitHubPing,
} from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';
import Placeholder from '../common/Placeholder';
import { showErrorSnackbar } from '../../redux/actions/appActions';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const TYPES = {
  Download: TASK_CONVERTER_GITHUB_DOWNLOAD,
  Upload: TASK_CONVERTER_GITHUB_UPLOAD,
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = taskType => {
  let schema = {
    branch: yup.string().required('The branch name is required'),
    accessKey: yup.string().required('The server API key is required'),
    repositoryName: yup.string().required('The repository name is required'),
    organizationName: yup.string().required('The organization name is required'),
  };

  if (taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
    const taskUploadSchema = {
      sourceFilePath: yup.string()
        .required('Source file path is required')
        .test(
          'start_end_with_space',
          'The element of the source file path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source file name cannot contain the new lines',
          function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'file-exist',
          'Source file does not exist or is not a file entity',
          async function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskUploadSchema);
  }

  if (taskType === TASK_CONVERTER_GITHUB_DOWNLOAD) {
    const taskDownloadSchema = {
      outputFilename: yup.string()
        .required('Output file name is required')
        .test(
          'start_end_with_space',
          'The output filename can not start or end with spaces',
          v => !(v.startsWith(' ') || v.endsWith(' ')),
        )
        .test(
          'allowed-characters',
        <>
          Output file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        )
        .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
        .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
        .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
        .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
      outputFolderPath: yup.string()
        .test(
          'required',
          'Output folder path is required',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && value !== '';
          },
        )
        .test(
          'start_end_with_space',
          'The element of the output folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Output folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Output folder name cannot contain the new lines',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('\n');
          },
        )
        .test(
          'not-contain-extension',
          'Output folder name cannot contain the extensions',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('.zip');
          },
        )
        .test(
          'folder-exist',
          'Output folder does not exist or is not a folder entity',
          async function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            if (value) {
              const parentResponse = await getPathEntity(value.split('/')[0]);
              if (!(
                parentResponse.status === 200
                  && parentResponse.data.data.type !== ENTITY_TYPE_FILE
              )
              ) {
                return this.createError({ message: 'Output parent folder does not exist' });
              }

              const valueResponse = await getPathEntity(value);

              return (
                valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
              );
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskDownloadSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const taskSource = task.source !== 'N/A' ? task.source : undefined;

  const [sourceFilePath, sourceFilename] = parsePath(taskSource || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    // external settings
    accessKey: '',
    repositoryName: config.repository_name || '',
    organizationName: config.organization_name || '',
    private: config.private || true,
    branch: config.branch || '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    outputFilename: outputFilename || '',

    outputFolderPath,
    outputFolderPathObjects: [],
    outputFolderPathSearchValue: '',
  };
};

function CreateGitHubDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [firstStepError, setFirstStepError] = useState('');
  const [enabledService, setEnabledService] = useState(false);

  const [checkBranchName, setCheckBranchName] = useState(false);
  const [checkRepositoryName, setCheckRepositoryName] = useState(false);

  const [repositoryExist, setRepositoryExist] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [taskType, setTaskType] = useState(rerunTask.converter || '');

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const { repositories, loading: repositoriesLoading } = useSelector(
    state => state.task.gitHubRepositories,
  );

  useEffect(() => {
    if (open && enabledService) {
      dispatch(fetchGitHubRepositories(
        formik.values.organizationName, formik.values.accessKey,
      ))
        .catch(() => dispatch(showErrorSnackbar('Can not load the GitHub repositories')));
    }
  }, [open, enabledService]);

  const { branches, loading: branchesLoading } = useSelector(
    state => state.task.gitHubBranches,
  );

  const validateRepositoryName = () => {
    setRepositoryExist(false);
    setCheckRepositoryName(false);

    if (taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      formik.setFieldError('repositoryName');

      repositories.forEach(repository => {
        if (repository.name === formik.values.repositoryName) {
          setRepositoryExist(true);
        }
      });

      setCheckRepositoryName(true);
    }

    dispatch(fetchGitHubBranches(
      formik.values.repositoryName,
      formik.values.organizationName,
      formik.values.accessKey,
    ))
      .catch(() => dispatch(showErrorSnackbar('Can not load the GitHub branches')));

    setCheckRepositoryName(true);
  };

  useEffect(() => {
    if (open && enabledService && formik.values.repositoryName !== '') {
      validateRepositoryName();
    }
  }, [open, enabledService, formik.values.repositoryName]);

  const validateBranchName = () => {
    if (taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      setCheckBranchName(false);

      formik.setFieldError('branch');

      branches.forEach(branch => {
        if (branch.name === formik.values.branch) {
          formik.setFieldError('branch', 'The branch name already exists');
        }
      });

      setCheckBranchName(true);
    }
  };

  useEffect(() => {
    if (open && enabledService && formik.values.repositoryName !== '') {
      validateBranchName();
    }
  }, [open, enabledService, formik.values.branch]);

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      validateRepositoryName();
    }
  }, [open, enabledService, repositories]);

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      validateBranchName();
    }
  }, [open, enabledService, branches]);

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const onFormSubmit = values => {
    setCreatingTask(true);

    let task = {
      organization_name: values.organizationName,
      repository_name: values.repositoryName,
      access_key: values.accessKey,
      branch: values.branch,
    };

    if (taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      const taskUpload = {
        source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
        private: values.private,
      };

      task = Object.assign(task, taskUpload);

      dispatch(createGitHubUploadTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }

    if (taskType === TASK_CONVERTER_GITHUB_DOWNLOAD) {
      const taskDownload = {
        output: `${outputFolder}${values.outputFilename}`,
      };

      task = Object.assign(task, taskDownload);

      dispatch(createGitHubDownloadTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  const config = {
    title: 'Create GitHub',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General settings',
        fields: [
          {
            tooltip: 'The organization name. The name is not case sensitive.',
            onChange: event => formik.handleChange(event),
            value: formik.values.organizationName,
            error: formik.errors.organizationName,
            placeholder: 'Organization Name',
            label: 'Organization Name',
            key: 'organizationName',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.accessKey,
            error: formik.errors.accessKey,
            placeholder: 'Access Key',
            label: 'Access Key',
            key: 'accessKey',
          },
          {
            item: ([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>,
            onChange: event => setTaskType(event.target.value),
            items: Object.entries(TYPES),
            renderValue: selected => {
              if (selected === '') return <Placeholder>Task type</Placeholder>;

              let returnValue = selected;
              Object.entries(TYPES).forEach(([key, value]) => {
                if (selected === value) returnValue = key;
              });

              return returnValue;
            },
            label: 'Task type',
            error: undefined,
            value: taskType,
            key: 'taskType',
          },
        ],
        isValid: (formik.values.organizationName !== '' && formik.errors.organizationName === undefined)
          && (formik.values.accessKey !== '' && formik.errors.accessKey === undefined),
        async onSubmit() {
          setFirstStepError('');
          setEnabledService(false);

          const value = await fetchGitHubPing(
            formik.values.organizationName,
            formik.values.accessKey,
          );

          if (value) setEnabledService(true);
          else setFirstStepError('Unable to find the organization, the access key is wrong, or you do not have access to the specified organization.');

          return value;
        },
        error: firstStepError,
        allowContinue: true,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [],
        allowContinue: true,
        loading: false,
        isValid: true,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask || repositoriesLoading || branchesLoading,
    allowContinue: true,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (open && formik.values.organizationName !== '') formik.validateField('organizationName');
  }, [formik.values.serverUrl]);

  useEffect(() => {
    if (open && formik.values.accessKey !== '') formik.validateField('accessKey');
  }, [formik.values.apiKey]);

  if (taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
    config.steps[1] = {
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.repositoryName,
          error: formik.errors.repositoryName,
          placeholder: 'Repository Name',
          label: 'Repository Name',
          key: 'repositoryName',
        },
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.branch,
          error: formik.errors.branch,
          placeholder: 'Branch Name',
          label: 'Branch',
          key: 'branch',
        },
      ],
      stepTooltip: repositoryExist ? 'The repository name already exists' : undefined,
      configurations: !repositoryExist ? [
        {
          tooltip: 'Whether the repository is private.',
          onChange: event => formik.handleChange(event),
          value: formik.values.private,
          label: 'Is Private',
          key: 'private',
        },
      ] : [],
      allowContinue: formik.values.repositoryName !== '' && formik.values.branch !== '' && checkRepositoryName && checkBranchName,
      isValid: formik.values.repositoryName !== '' && formik.values.branch !== '' && formik.errors.branch === undefined,
      loading: repositoriesLoading || branchesLoading,
      onSubmit: () => Promise.resolve(true),
    };

    config.steps.push({
      title: 'Source file path',
      type: STEP_FIELD_TYPE_PATH,
      pathField: {
        objects: filterObjects(
          formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
        ),
        onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
        value: formik.values.sourceFilePath !== ''
          ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
          : formik.values.sourceFilePathSearchValue,
        error: formik.errors.sourceFilePath,
        loading: false,
      },
      allowContinue: formik.values.sourceFilePath !== '',
      isValid: formik.errors.sourceFilePath === undefined,
      loading: creatingTask || loadingSourceFileObjects,
      onSubmit: () => Promise.resolve(true),
    });
  }

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_GITHUB_UPLOAD) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [open, enabledService, formik.values.sourceFilePath]);

  if (taskType === TASK_CONVERTER_GITHUB_DOWNLOAD) {
    let allowCreateFolders = false;
    if (formik.errors.outputFolderPath !== undefined) {
      if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
        allowCreateFolders = true;
      }
    }

    config.steps[1] = {
      type: STEP_FIELD_TYPE_MULTIPLE,
      title: 'Task details',
      fields: [
        {
          items: repositories,
          item: item => <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>,
          onChange: event => formik.handleChange(event),
          value: formik.values.repositoryName,
          error: formik.errors.repositoryName,
          placeholder: 'Repository Name',
          label: 'Repository Name',
          key: 'repositoryName',
        },
        {
          items: formik.values.repositoryName !== '' ? branches : [],
          item: item => <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>,
          onChange: event => formik.handleChange(event),
          value: formik.values.branch,
          error: formik.errors.branch,
          placeholder: 'Branch Name',
          label: 'Branch Name',
          key: 'branch',
        },
      ],
      allowContinue: formik.values.repositoryName !== '' && formik.values.branch !== '',
      isValid: formik.values.repositoryName !== '' && formik.values.branch !== '',
      onSubmit: () => Promise.resolve(true),
      loading: false,
    };

    config.steps.push({
      title: 'Output file path',
      type: STEP_FIELD_TYPE_PATH,
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.outputFilename,
          error: formik.errors.outputFilename,
          placeholder: 'Example.zip',
          label: 'Output file name',
          key: 'outputFilename',
        },
      ],
      pathField: {
        label: 'Output folder path',
        placeholder: 'Search a folder by name',
        objects: filterObjects(
          formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
        ).filter(o => o.type === 'folder'),
        onChange: value => {
          onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
          formik.setFieldValue('createFolders', false);
        },
        value: formik.values.outputFolderPath !== ''
          ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
          : formik.values.outputFolderPathSearchValue,
      },
      configurations: allowCreateFolders ? [
        {
          tooltip: (
            <p>
              <p>
                The output folder does not exist. If you select this parameter,
                the folder will be created automatically.
              </p>
              <p>
                <b>Notice. </b>
                Use this parameter carefully.
              </p>
              <p>
                THE PARENT FOLDER MUST EXIST.
              </p>
            </p>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createFolders,
          label: 'Create Folders',
          key: 'createFolders',
        },
      ] : [],
      allowContinue: formik.values.outputFolderPath !== '' && formik.values.outputFilename !== ''
        && (formik.errors.outputFolderPath === undefined || formik.values.createFolders),
      isValid: formik.values.outputFolderPath !== ''
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      onSubmit: () => Promise.resolve(true),
      loading: creatingTask || loadingOutputFolderObjects,
      error: formik.errors.outputFolderPath,
      warning: outputFileWarning,
    });
  }

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open && enabledService && taskType === TASK_CONVERTER_GITHUB_DOWNLOAD) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [taskType, enabledService, formik.values.outputFolderPath]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreateGitHubDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateGitHubDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateGitHubDialog;
