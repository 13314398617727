import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { generateKey, getScoreBarScale, useQuery } from '../common/Utility';
import ReportScoreRow from './ReportScoreRow';
import ScoreBar from './ScoreBar';

function TopLevelCategory({ configuration }) {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  return (
    <ReportScoreRow
      buttonOnClick={() => history.push(`${location.pathname}#tab=${query.get('tab')}&top-level-category=${configuration.name}`)}
      subScores={configuration.sub_scores}
      maxScore={configuration.max_score}
      reportName={configuration.name}
      score={configuration.score}
      key={configuration.name}
    />
  );
}

TopLevelCategory.propTypes = {
  configuration: PropTypes.object.isRequired,
};

function TopLevelCategories({ name, scores }) {
  const overallScore = scores.reduce((acc, v) => acc + v.score, 0);
  const maxScore = scores.reduce((acc, v) => acc + v.max_score, 0) || 50;

  return (
    <Grid container justifyContent="center">
      <Grid className="pt-4" item xs={10}>
        <ScoreBar
          title={`Your Overall Score${name ? ` for '${name}'` : ''}`}
          scale={getScoreBarScale(maxScore)}
          score={overallScore}
          maxScore={maxScore}
        />
      </Grid>

      <Grid item xs={9}>
        {scores.sort((a, b) => b.score - a.score).map(report => (
          <TopLevelCategory key={generateKey(report.name)} configuration={report} />
        ))}
      </Grid>
    </Grid>
  );
}

TopLevelCategories.defaultProps = {
  name: undefined,
};

TopLevelCategories.propTypes = {
  scores: PropTypes.array.isRequired,
  name: PropTypes.string,
};

export default TopLevelCategories;
