export const USER_SIGN_IN = 'USER_SIGN_IN';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';

export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';

export const IS_MOBILE = 'IS_MOBILE';
export const IS_DESKTOP = 'IS_DESKTOP';

export const FETCH_OBJECTS_FROM_PATH_START = 'FETCH_OBJECTS_FROM_PATH_START';
export const FETCH_OBJECTS_FROM_PATH_SUCCESS = 'FETCH_OBJECTS_FROM_PATH_SUCCESS';
export const FETCH_OBJECTS_FROM_PATH_FAIL = 'FETCH_OBJECTS_FROM_PATH_FAIL';

export const DOWNLOADING_FILE_START = 'DOWNLOADING_FILE_START';
export const DOWNLOADING_FILE_FINISH = 'DOWNLOADING_FILE_FINISH';

export const FETCH_FILE_TAGS_START = 'FETCH_FILE_TAGS_START';
export const FETCH_FILE_TAGS_SUCCESS = 'FETCH_FILE_TAGS_SUCCESS';
export const FETCH_FILE_TAGS_FAIL = 'FETCH_FILE_TAGS_FAIL';

export const FETCH_ZIP_FILE_LIST_START = 'FETCH_ZIP_FILE_LIST_START';
export const FETCH_ZIP_FILE_LIST_SUCCESS = 'FETCH_ZIP_FILE_LIST_SUCCESS';
export const FETCH_ZIP_FILE_LIST_FAIL = 'FETCH_ZIP_FILE_LIST_FAIL';

export const FETCH_FILE_STORAGE_CONFIGURATION_START = 'FETCH_FILE_STORAGE_CONFIGURATION_START';
export const FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS = 'FETCH_FILE_STORAGE_CONFIGURATION_SUCCESS';
export const FETCH_FILE_STORAGE_CONFIGURATION_FAIL = 'FETCH_FILE_STORAGE_CONFIGURATION_FAIL';

export const FETCH_TASKS_START = 'FETCH_TASKS_START';
export const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS';
export const FETCH_TASKS_FAIL = 'FETCH_TASKS_FAIL';

export const FETCH_TASK_START = 'FETCH_TASK_START';
export const FETCH_TASK_SUCCESS = 'FETCH_TASK_SUCCESS';
export const FETCH_TASK_FAIL = 'FETCH_TASK_FAIL';

export const FETCH_LATEST_TASKS_START = 'FETCH_LATEST_TASKS_START';
export const FETCH_LATEST_TASKS_SUCCESS = 'FETCH_LATEST_TASKS_SUCCESS';
export const FETCH_LATEST_TASKS_FAIL = 'FETCH_LATEST_TASKS_FAIL';

export const FETCH_SDL_FOLDERS_START = 'FETCH_SDL_FOLDERS_START';
export const FETCH_SDL_FOLDERS_SUCCESS = 'FETCH_SDL_FOLDERS_SUCCESS';
export const FETCH_SDL_FOLDERS_FAIL = 'FETCH_SDL_FOLDERS_FAIL';

export const FETCH_EVALUATION_SCORE_START = 'FETCH_EVALUATION_SCORE_START';
export const FETCH_EVALUATION_SCORE_SUCCESS = 'FETCH_EVALUATION_SCORE_SUCCESS';
export const FETCH_EVALUATION_SCORE_FAIL = 'FETCH_EVALUATION_SCORE_FAIL';

export const FETCH_EVALUATION_PDF_START = 'FETCH_EVALUATION_PDF_START';
export const FETCH_EVALUATION_PDF_SUCCESS = 'FETCH_EVALUATION_PDF_SUCCESS';
export const FETCH_EVALUATION_PDF_FAIL = 'FETCH_EVALUATION_PDF_FAIL';

export const FETCH_EVALUATION_HTML_START = 'FETCH_EVALUATION_HTML_START';
export const FETCH_EVALUATION_HTML_SUCCESS = 'FETCH_EVALUATION_HTML_SUCCESS';
export const FETCH_EVALUATION_HTML_FAIL = 'FETCH_EVALUATION_HTML_FAIL';

export const FETCH_IAM_CURRENT_USER_START = 'FETCH_IAM_CURRENT_USER_START';
export const FETCH_IAM_CURRENT_USER_SUCCESS = 'FETCH_IAM_CURRENT_USER_SUCCESS';
export const FETCH_IAM_CURRENT_USER_FAIL = 'FETCH_IAM_CURRENT_USER_FAIL';

export const FETCH_GITHUB_REPORISORIES_START = 'FETCH_GITHUB_REPORISORIES_START';
export const FETCH_GITHUB_REPORISORIES_SUCCESS = 'FETCH_GITHUB_REPORISORIES_SUCCESS';
export const FETCH_GITHUB_REPORISORIES_FAIL = 'FETCH_GITHUB_REPORISORIES_FAIL';

export const FETCH_GITHUB_BRANCHES_START = 'FETCH_GITHUB_BRANCHES_START';
export const FETCH_GITHUB_BRANCHES_SUCCESS = 'FETCH_GITHUB_BRANCHES_SUCCESS';
export const FETCH_GITHUB_BRANCHES_FAIL = 'FETCH_GITHUB_BRANCHES_FAIL';

export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
