import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { generateKey, getScoreBarScale, useQuery } from '../common/Utility';
import ReportScoreRow from './ReportScoreRow';
import ScoreBar from './ScoreBar';

function SubCategory({ topLevelCategory, configuration }) {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  return (
    <ReportScoreRow
      buttonOnClick={() => history.push(`${location.pathname}#tab=${query.get('tab')}&top-level-category=${topLevelCategory}&sub-category=${configuration.name}`)}
      subScores={configuration.sub_scores}
      maxScore={configuration.max_score}
      reportName={configuration.name}
      score={configuration.score}
      key={configuration.name}
    />
  );
}

SubCategory.propTypes = {
  topLevelCategory: PropTypes.string.isRequired,
  configuration: PropTypes.object.isRequired,
};

function SubCategories({ configuration }) {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();

  return (
    <Grid container justifyContent="center">
      <Grid className="pt-4" item xs={10}>
        <ScoreBar
          scale={getScoreBarScale(configuration.max_score)}
          title={`Your ${configuration.name} Score`}
          maxScore={configuration.max_score}
          score={configuration.score}
        />
      </Grid>

      <Grid item xs={9}>
        {configuration.sub_scores.sort((a, b) => b.score - a.score).map(report => (
          <SubCategory
            topLevelCategory={configuration.name}
            key={generateKey(configuration.name)}
            configuration={report}
          />
        ))}
      </Grid>

      <Grid className="py-4" item xs={10}>
        <Button
          onClick={() => history.push(`${location.pathname}#tab=${query.get('tab')}`)}
          className="button"
          variant="contained"
          color="primary"
        >
          Back To Overall Report
        </Button>
      </Grid>
    </Grid>
  );
}

SubCategories.propTypes = {
  configuration: PropTypes.object.isRequired,
};

export default SubCategories;
