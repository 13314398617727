import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import CreateTaskDialog from './CreateTaskDialog';
import {
  ENTITY_TYPE_FILE,
  TASK_CONVERTER_ZOOMIN_UPLOAD,
} from '../../core/entities';
import { createZoominUploadTask } from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = taskType => {
  let schema = {
    zoominBaseUrl: yup.string().required('The base url is required'),
  };

  if (taskType === TASK_CONVERTER_ZOOMIN_UPLOAD) {
    const taskUploadSchema = {
      zoominUploadPassword: yup.string().required('The upload password is required'),
      sourceFilePath: yup.string()
        .required('Source file path is required')
        .test(
          'start_end_with_space',
          'The element of the source file path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Source file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const { sourceFilePathSearchValue } = this.parent;
          const value = `${v}/${sourceFilePathSearchValue}`;

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Source file name cannot contain the new lines',
          function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            return value && !value.includes('\n');
          },
        )
        .test(
          'file-exist',
          'Source file does not exist or is not a file entity',
          async function (v) {
            const { sourceFilePathSearchValue } = this.parent;
            const value = `${v}/${sourceFilePathSearchValue}`;

            if (value) {
              const response = await getPathEntity(value);

              return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskUploadSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');

  return {
    zoominBaseUrl: config.zoomin_base_url || '',
    zoominUploadPassword: '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],
  };
};

function CreateZoominTaskDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);

  const [
    taskType,
    // setTaskType
  ] = useState(rerunTask.converter || TASK_CONVERTER_ZOOMIN_UPLOAD);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    setCreatingTask(true);

    let task = { zoomin_base_url: values.zoominBaseUrl };

    if (taskType === TASK_CONVERTER_ZOOMIN_UPLOAD) {
      const taskExport = {
        source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
        zoomin_upload_password: values.zoominUploadPassword,
      };

      task = Object.assign(task, taskExport);

      dispatch(createZoominUploadTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));
    }
  };

  useEffect(() => {
    if (open) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [formik.values.sourceFilePath, open]);

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  const config = {
    title: 'Create Zoomin Upload',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Zoomin server settings',
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.zoominBaseUrl,
            error: formik.errors.zoominBaseUrl,
            placeholder: 'Base Url',
            key: 'zoominBaseUrl',
            label: 'Base Url',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.zoominUploadPassword,
            error: formik.errors.zoominUploadPassword,
            placeholder: 'Upload Password',
            key: 'zoominUploadPassword',
            label: 'Upload Password',
            type: 'password',
          },
        ],
        isValid: (formik.values.zoominBaseUrl !== '' && formik.errors.zoominBaseUrl === undefined)
          && (formik.values.zoominUploadPassword !== '' && formik.errors.zoominUploadPassword === undefined),
        onSubmit: () => Promise.resolve(true),
        allowContinue: true,
        loading: false,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask,
    allowContinue: true,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (open && formik.values.zoominBaseUrl !== '') formik.validateField('zoominBaseUrl');
  }, [formik.values.zoominBaseUrl]);

  useEffect(() => {
    if (open && formik.values.zoominUploadPassword !== '') formik.validateField('zoominUploadPassword');
  }, [formik.values.zoominUploadPassword]);

  if (taskType === TASK_CONVERTER_ZOOMIN_UPLOAD) {
    config.steps.push({
      title: 'Source file path',
      type: STEP_FIELD_TYPE_PATH,
      pathField: {
        objects: filterObjects(
          formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
        ),
        onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
        value: formik.values.sourceFilePath !== ''
          ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
          : formik.values.sourceFilePathSearchValue,
        error: formik.errors.sourceFilePath,
        loading: false,
      },
      allowContinue: formik.values.sourceFilePath !== '',
      isValid: formik.errors.sourceFilePath === undefined,
      onSubmit: () => Promise.resolve(true),
      loading: loadingSourceFileObjects,
    });
  }

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreateZoominTaskDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateZoominTaskDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateZoominTaskDialog;
