import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Grid,
  Link, makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { fetchTaskWarnings } from '../../redux/actions/taskActions';
import { formatDateStr, isObjectEmpty } from '../../core/services/commonService';
import { TaskEntity } from '../../core/entities';
import Loader from '../common/Loader';

const useStyles = makeStyles(theme => ({
  tableHeadRow: {
    '& > :first-child': {
      width: 200,
    },
  },
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function LoadingLink({
  loading, onClick, children, ...rest
}) {
  const handleClick = () => {
    if (!loading) {
      onClick();
    }
  };

  return (
    <Link
      className="ml-1"
      onClick={handleClick}
      {...rest}
    >
      {loading && (
      <div>
        Loading
        {' '}
        <CircularProgress size={15} color="inherit" />
      </div>
      )}
      {!loading && children}
    </Link>
  );
}

LoadingLink.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

function TaskDetailsWarnings({ task }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [events, setEvents] = useState([]);
  const [firstLoading, setFirstLoading] = useState(false);

  const logsExist = events.length > 0;

  const fetchLogs = token => dispatch(fetchTaskWarnings(task.id, token));

  const firstLogsLoad = () => {
    setFirstLoading(true);

    if (!isObjectEmpty(task)) {
      fetchLogs()
        .then(res => setEvents(res.events))
        .finally(() => setFirstLoading(false));
    }
  };

  useEffect(() => {
    if (task.started) firstLogsLoad();
  }, []); // eslint-disable-line

  if (firstLoading) {
    return <Loader />;
  }

  return (
    <Grid className="pt-4" container>

      <Table size="small">
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell>Time</TableCell>
            <TableCell>Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {logsExist && events.map(event => (
            <TableRow className={classes.tableRow} key={event.id}>
              <TableCell component="th">{formatDateStr(new Date(event.timestamp))}</TableCell>
              <TableCell><span style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>{event.message}</span></TableCell>
            </TableRow>
          ))}

          {!logsExist && (
            <TableRow className={classes.tableRow}>
              <TableCell />
              <TableCell>
                During getting the task warnings, something goes wrong.
              </TableCell>
            </TableRow>
          )}

        </TableBody>
      </Table>

    </Grid>
  );
}

TaskDetailsWarnings.propTypes = {
  task: PropTypes.oneOfType([
    PropTypes.instanceOf(TaskEntity),
    PropTypes.exact({}),
  ]).isRequired,
};

export default TaskDetailsWarnings;
