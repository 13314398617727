import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Typography, withStyles } from '@material-ui/core';
import { TASK_STATUS_FINISHED } from '../../core/entities';
import { MAIN_UI_COLOR } from '../../config';

export function TextValue({ children, ...rest }) {
  return <Typography {...rest} variant="body1">{children}</Typography>;
}

TextValue.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TextLabel = withStyles({
  root: {
    fontWeight: 'bold',
  },
})(Typography);

export const useQuery = () => new URLSearchParams(useLocation().hash.replace('#', ''));

export const generateStatusField = value => {
  let color = '#F2994A';
  if (value === TASK_STATUS_FINISHED) color = MAIN_UI_COLOR;

  return <span style={{ color, fontWeight: 500 }}>{capitalize(value)}</span>;
};

const MINUTE_IN_SECONDS = 1 * 60;
const HOUR_IN_SECONDS = 1 * 60 * 60;

export const getHumanReadebleTimeDiff = (newTime, oldTime) => {
  let seconds = Math.trunc((new Date(newTime) - new Date(oldTime)) / 1000);

  let minutes = 0;
  let hours = 0;

  if (seconds > HOUR_IN_SECONDS) {
    hours = Math.trunc(seconds / HOUR_IN_SECONDS);
    seconds %= HOUR_IN_SECONDS;
  }

  if (seconds > MINUTE_IN_SECONDS) {
    minutes = Math.trunc(seconds / MINUTE_IN_SECONDS);
    seconds %= MINUTE_IN_SECONDS;
  }

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

export const getCorrectPath = (path, searchValue) => {
  let value = `${path}${path === '' || path.endsWith('/') ? '' : '/'}`;

  if (searchValue !== '') {
    value += `${searchValue}${searchValue.endsWith('/') ? '' : '/'}`;
  }

  return value;
};

const RESERVED_URL_CHARACTERS = ['!', '*', '\'', '(', ')', ';', ':', '@', '&', '=', '+', '$', ',', '/', '?', '#', '[', ']'];

export const normalizeFilename = filename => {
  let returnValue = filename;

  RESERVED_URL_CHARACTERS.forEach(char => {
    returnValue = returnValue.replaceAll(char, '');
  });

  return returnValue;
};

export const getScoreBarScale = maxScore => {
  const step = maxScore / 5;

  return [
    { title: 'VERY POOR', score: `1 -  ${step}` },
    { title: 'POOR', score: `${step} - ${2 * step}` },
    { title: 'FAIR', score: `${2 * step} - ${3 * step}` },
    { title: 'GOOD', score: `${3 * step} - ${4 * step}` },
    { title: 'VERY GOOD', score: `${4 * step} - ${maxScore}` },
  ];
};

export const generateKey = pre => `${pre}_${Math.random() * (99999 - 10000) + 10000}`;

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};
