import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { MenuItem } from '@material-ui/core';
import CreateTaskDialog from './CreateTaskDialog';
import {
  ENTITY_TYPE_FILE,
  TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT,
} from '../../core/entities';
import { createZoominExportTask } from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';
import Placeholder from '../common/Placeholder';

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

// const TYPES = {
//   Export: TASK_CONVERTER_ZOOMIN_EXPORT,
// };

const TYPES_FILTER = {
  Resource: 'ResourceBundle',
  HTML: 'HTMLBundle',
  All: 'all',
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

const validationSchema = taskType => {
  let schema = {
    zoominBaseUrl: yup.string().required('The base url is required'),
    zoominApiSecret: yup.string().required('The API secret is required'),
    zoominApiAccessKey: yup.string().required('The API access key is required'),
    fluidtopicsServerUrl: yup.string().required('The server url is required'),
    fluidtopicsApiKey: yup.string().required('The API key is required'),
    fluidtopicsSourceId: yup.string().required('The source id is required'),
    startAfterBundle: yup.string(),
    maxPages: yup.number(),
  };

  if (taskType === TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT) {
    const taskDownloadSchema = {
      outputFilename: yup.string()
        .required('Output file name is required')
        .test(
          'start_end_with_space',
          'The output filename can not start or end with spaces',
          v => !(v.startsWith(' ') || v.endsWith(' ')),
        )
        .test(
          'allowed-characters',
        <>
          Output file name can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
        )
        .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
        .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
        .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
        .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
      outputFolderPath: yup.string()
        .test(
          'required',
          'Output folder path is required',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && value !== '';
          },
        )
        .test(
          'start_end_with_space',
          'The element of the output folder path can not start or end with spaces',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            let result = true;
            value.split('/').forEach(path => {
              if (path.startsWith(' ') || path.endsWith(' ')) {
                result = false;
              }
            });

            return result;
          },
        )
        .test(
          'allowed-characters',
        <>
          Output folder can contain only the next characters:
          <br />
          {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
        </>,
        function (v) {
          const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

          return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
        },
        )
        .test(
          'not-new-lines',
          'Output folder name cannot contain the new lines',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('\n');
          },
        )
        .test(
          'not-contain-extension',
          'Output folder name cannot contain the extensions',
          function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            return value && !value.includes('.zip');
          },
        )
        .test(
          'folder-exist',
          'Output folder does not exist or is not a folder entity',
          async function (v) {
            const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

            if (value) {
              const parentResponse = await getPathEntity(value.split('/')[0]);
              if (!(
                parentResponse.status === 200
                  && parentResponse.data.data.type !== ENTITY_TYPE_FILE
              )
              ) {
                return this.createError({ message: 'Output parent folder does not exist' });
              }

              const valueResponse = await getPathEntity(value);

              return (
                valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE
              );
            }

            return false;
          },
        ),
    };

    schema = Object.assign(schema, taskDownloadSchema);
  }

  return yup.object(schema);
};

const generateInitValues = task => {
  const config = JSON.parse(task.config || '{}');

  const [outputFolderPath, outputFilename] = parsePath(task.output || '');

  return {
    // external settings
    zoominBaseUrl: config.zoomin_base_url || '',
    zoominApiAccessKey: '',
    zoominApiSecret: '',

    fluidtopicsServerUrl: config.fluidtopics_server_url || '',
    fluidtopicsSourceId: config.fluidtopics_source_id || '',
    fluidtopicsApiKey: '',

    startAfterBundle: config.start_after_bundle || undefined,

    batchMethod: config.batch_method || false,
    typeFilter: config.type_filter || 'all',
    maxPages: config.max_pages || undefined,

    outputFilename: outputFilename || '',

    outputFolderPath,
    outputFolderPathObjects: [],
    outputFolderPathSearchValue: '',
  };
};

function CreateZoominToFluidTopicsDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [
    taskType,
    // setTaskType
  ] = useState(rerunTask.converter || TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema: validationSchema(taskType),
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const onFormSubmit = values => {
    setCreatingTask(true);

    let task = {
      zoomin_base_url: values.zoominBaseUrl,
      zoomin_api_access_key: values.zoominApiAccessKey,
      zoomin_api_secret: values.zoominApiSecret,
      fluidtopics_server_url: values.fluidtopicsServerUrl,
      fluidtopics_source_id: values.fluidtopicsSourceId,
      fluidtopics_api_key: values.fluidtopicsApiKey,
    };

    if (taskType === TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT) {
      let taskExport = {
        output: `${outputFolder}${values.outputFilename}`,
        batch_method: values.batchMethod,
      };

      if (values.maxPages !== undefined) {
        taskExport = Object.assign(taskExport, { max_pages: parseInt(values.maxPages, 10) });
      }

      const typeFilter = values.typeFilter === 'all' ? undefined : values.typeFilter;
      if (typeFilter !== undefined) {
        taskExport = Object.assign(taskExport, { type_filter: typeFilter });
      }

      if (values.startAfterBundle !== undefined && values.startAfterBundle !== '') {
        taskExport = Object.assign(taskExport, { start_after_bundle: values.startAfterBundle });
      }

      task = Object.assign(task, taskExport);

      dispatch(createZoominExportTask(task))
        .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
        .catch(() => setCreatingTask(false));

      // console.log(task);
      // setCreatingTask(false);
    }
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  const config = {
    title: 'Create Export From Zoomin To Fluid Topics',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'General task details',
        fields: [
          {
            tooltip: (
              <>
                <p>
                  <b>Resource</b>
                  {' '}
                  - only bundles of type ResourceBundle.
                </p>
                <p>
                  <b>HTML</b>
                  {' '}
                  - only bundles of type HTMLBundle.
                </p>
                <p>
                  <b>All</b>
                  {' '}
                  - processes both types of bundles.
                  {' '}
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            item: ([key, value]) => <MenuItem key={value} value={value}>{key}</MenuItem>,
            items: Object.entries(TYPES_FILTER),
            renderValue: selected => {
              if (selected === '') return <Placeholder>Type Filter</Placeholder>;

              let returnValue = selected;
              Object.entries(TYPES_FILTER).forEach(([key, value]) => {
                if (selected === value) returnValue = key;
              });

              return returnValue;
            },
            value: formik.values.typeFilter,
            label: 'Type Filter',
            key: 'typeFilter',
            error: undefined,
          },
          {
            tooltip: 'Specify the bundle name from which we need to start the import process.',
            onChange: event => formik.handleChange(event),
            value: formik.values.startAfterBundle,
            error: formik.errors.startAfterBundle,
            placeholder: 'Start After Bundle',
            label: 'Start After Bundle',
            key: 'startAfterBundle',
          },
          {
            tooltip: 'Number of pages to process during the import process. One page equals 100 bundles. Leave this field blank if you want to process all bundles.',
            onChange: event => formik.handleChange(event),
            value: formik.values.maxPages,
            error: formik.errors.maxPages,
            placeholder: 'Max Pages',
            label: 'Max Pages',
            key: 'maxPages',
          },
        ],
        configurations: [
          {
            tooltip: 'The batch method changes the upload source. The bundles are distributed to the batches (max 20 bundles and 2000 bundle pages per batch). If you do not select this configuration, we will process bundle by bundle.',
            onChange: event => formik.handleChange(event),
            value: formik.values.batchMethod,
            label: 'Use the batch method',
            key: 'batchMethod',
          },
        ],
        isValid: formik.errors.maxPages === undefined,
        onSubmit: () => Promise.resolve(true),
        allowContinue: true,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Zoomin server settings',
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.zoominBaseUrl,
            error: formik.errors.zoominBaseUrl,
            placeholder: 'Base Url',
            key: 'zoominBaseUrl',
            label: 'Base Url',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.zoominApiAccessKey,
            error: formik.errors.zoominApiAccessKey,
            placeholder: 'API Access Key',
            label: 'API Access Key',
            key: 'zoominApiAccessKey',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.zoominApiSecret,
            error: formik.errors.zoominApiSecret,
            placeholder: 'API Secret',
            type: 'password',
            label: 'API Secret',
            key: 'zoominApiSecret',
          },
        ],
        isValid: (formik.values.zoominBaseUrl !== '' && formik.errors.zoominBaseUrl === undefined)
          && (formik.values.zoominApiAccessKey !== '' && formik.errors.zoominApiAccessKey === undefined)
          && (formik.values.zoominApiSecret !== '' && formik.errors.zoominApiSecret === undefined),
        onSubmit: () => Promise.resolve(true),
        allowContinue: true,
        loading: false,
      },
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Fluid Topics settings',
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.fluidtopicsServerUrl,
            error: formik.errors.fluidtopicsServerUrl,
            key: 'fluidtopicsServerUrl',
            placeholder: 'Base Url',
            label: 'Base Url',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.fluidtopicsApiKey,
            error: formik.errors.fluidtopicsApiKey,
            key: 'fluidtopicsApiKey',
            placeholder: 'API Key',
            type: 'password',
            label: 'API Key',
          },
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.fluidtopicsSourceId,
            error: formik.errors.fluidtopicsSourceId,
            key: 'fluidtopicsSourceId',
            placeholder: 'Source Id',
            label: 'Source Id',
          },
        ],
        isValid: (formik.values.fluidtopicsServerUrl !== '' && formik.errors.fluidtopicsServerUrl === undefined)
          && (formik.values.fluidtopicsApiKey !== '' && formik.errors.fluidtopicsApiKey === undefined)
          && (formik.values.fluidtopicsSourceId !== '' && formik.errors.fluidtopicsSourceId === undefined),
        onSubmit: () => Promise.resolve(true),
        allowContinue: true,
        loading: false,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    loading: creatingTask,
    allowContinue: true,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (open && formik.values.zoominBaseUrl !== '') formik.validateField('zoominBaseUrl');
  }, [formik.values.zoominBaseUrl]);

  useEffect(() => {
    if (open && formik.values.zoominApiAccessKey !== '') formik.validateField('zoominApiAccessKey');
  }, [formik.values.zoominApiAccessKey]);

  useEffect(() => {
    if (open && formik.values.zoominApiSecret !== '') formik.validateField('zoominApiSecret');
  }, [formik.values.zoominApiAccessKey]);

  useEffect(() => {
    if (open && formik.values.fluidtopicsServerUrl !== '') formik.validateField('fluidtopicsServerUrl');
  }, [formik.values.fluidtopicsServerUrl]);

  useEffect(() => {
    if (open && formik.values.fluidtopicsApiKey !== '') formik.validateField('fluidtopicsApiKey');
  }, [formik.values.fluidtopicsApiKey]);

  useEffect(() => {
    if (open && formik.values.fluidtopicsSourceId !== '') formik.validateField('fluidtopicsSourceId');
  }, [formik.values.fluidtopicsSourceId]);

  useEffect(() => {
    if (open && formik.values.startAfterBundle !== '') formik.validateField('startAfterBundle');
  }, [formik.values.startAfterBundle]);

  useEffect(() => {
    if (open && formik.values.maxPages !== '') formik.validateField('maxPages');
  }, [formik.values.maxPages]);

  if (taskType === TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT) {
    let allowCreateFolders = false;
    if (formik.errors.outputFolderPath !== undefined) {
      if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
        allowCreateFolders = true;
      }
    }

    config.steps.push({
      title: 'Output file path',
      type: STEP_FIELD_TYPE_PATH,
      fields: [
        {
          onChange: event => formik.handleChange(event),
          value: formik.values.outputFilename,
          error: formik.errors.outputFilename,
          placeholder: 'Example.zip',
          label: 'Output file name',
          key: 'outputFilename',
        },
      ],
      pathField: {
        label: 'Output folder path',
        placeholder: 'Search a folder by name',
        objects: filterObjects(
          formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
        ).filter(o => o.type === 'folder'),
        onChange: value => {
          onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
          formik.setFieldValue('createFolders', false);
        },
        value: formik.values.outputFolderPath !== ''
          ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
          : formik.values.outputFolderPathSearchValue,
      },
      configurations: allowCreateFolders ? [
        {
          tooltip: (
            <p>
              <p>
                The output folder does not exist. If you select this parameter,
                the folder will be created automatically.
              </p>
              <p>
                <b>Notice. </b>
                Use this parameter carefully.
              </p>
              <p>
                THE PARENT FOLDER MUST EXIST.
              </p>
            </p>
          ),
          onChange: event => formik.handleChange(event),
          value: formik.values.createFolders,
          label: 'Create Folders',
          key: 'createFolders',
        },
      ] : [],
      allowContinue: formik.values.outputFolderPath !== '' && formik.values.outputFilename !== ''
        && (formik.errors.outputFolderPath === undefined || formik.values.createFolders),
      isValid: formik.values.outputFolderPath !== ''
        && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
      onSubmit: () => Promise.resolve(true),
      loading: creatingTask || loadingOutputFolderObjects,
      error: formik.errors.outputFolderPath,
      warning: outputFileWarning,
    });
  }

  useEffect(() => {
    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  useEffect(() => {
    if (open && taskType === TASK_CONVERTER_ZOOMIN_TO_FLUID_TOPICS_EXPORT) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [taskType, formik.values.outputFolderPath]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename]);

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreateZoominToFluidTopicsDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateZoominToFluidTopicsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateZoominToFluidTopicsDialog;
