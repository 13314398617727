import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ListItemText, MenuItem, Checkbox } from '@material-ui/core';
import * as yup from 'yup';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import { createPandocTask } from '../../redux/actions/taskActions';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { checkFileExist, getObjectsFromPath, getPathEntity } from '../../redux/actions/fileStorageActions';
import { ENTITY_TYPE_FILE, TASK_ENGINE_PANDOC } from '../../core/entities';
import { AUTOMATIC_REPORTS_MAPS, TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';

const READ_FORMAT_OPTIONS = ['docx', 'jira', 'csv', 'gfm', 'html', 'commonmark', 'commonmark_x'];
const WRITE_FORMAT_OPTIONS = ['html', 'docx', 'docbook5', 'commonmark', 'commonmark_x'];

const STEP_FIELD_TYPE_MULTIPLE = 'multiple';
const STEP_FIELD_TYPE_PATH = 'path';

const validationSchema = yup.object({
  readFormat: yup.string().required('Read format is required'),
  writeFormat: yup.string().required('Write format is required'),
  outputFilename: yup.string()
    .required('Output file name is required')
    .test(
      'start_end_with_space',
      'The output filename can not start or end with spaces',
      v => !(v.startsWith(' ') || v.endsWith(' ')),
    )
    .test(
      'allowed-characters',
    <>
      Output file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
    .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
    .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
  sourceFilePath: yup.string()
    .required('Source file path is required')
    .test(
      'start_end_with_space',
      'The element of the source file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Source file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const { sourceFilePathSearchValue } = this.parent;
      const value = `${v}/${sourceFilePathSearchValue}`;

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Source file name cannot contain the new lines',
      function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        return value && !value.includes('\n');
      },
    )
    .test(
      'file-exist',
      'Source file does not exist or is not a file entity',
      async function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        if (value) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  outputFolderPath: yup.string()
    .test(
      'required',
      'Output folder path is required',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && value !== '';
      },
    )
    .test(
      'start_end_with_space',
      'The element of the output folder path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Output folder can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Output folder name cannot contain the new lines',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('\n');
      },
    )
    .test(
      'not-contain-extension',
      'Output folder name cannot contain the extensions',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('.zip');
      },
    )
    .test(
      'folder-exist',
      'Output folder does not exist or is not a folder entity',
      async function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        if (value) {
          const parentResponse = await getPathEntity(value.split('/')[0]);
          if (!(
            parentResponse.status === 200
              && parentResponse.data.data.type !== ENTITY_TYPE_FILE
          )
          ) {
            return this.createError({ message: 'Output parent folder does not exist' });
          }

          const valueResponse = await getPathEntity(value);

          return valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  comparingFilePath: yup.string().nullable()
    .test(
      'start_end_with_space',
      'The element of the comparing file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Comparing file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

      if (!(value === null || value === undefined) && !/^[a-zA-Z0-9\s/\-._]+$/.test(value)) return false;

      return true;
    },
    )
    .test(
      'not-new-lines',
      'Comparing file name cannot contain the new lines',
      function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined) && value.includes('\n')) return false;

        return true;
      },
    )
    .test(
      'not-file-exist',
      'Comparing file does not exist or is not a file entity',
      async function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined)) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return true;
      },
    ),
});

const generateInitValues = task => {
  const [comparingFilePath, comparingFileName] = parsePath(task.expectedResult || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');
  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');

  const converter = task.converter || '2';
  const [readFormat, writeFormat] = converter.split('2');

  return {
    readFormat,
    writeFormat,
    outputFilename: outputFilename || '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    outputFolderPath,
    outputFolderPathSearchValue: '',
    outputFolderPathObjects: [],

    comparingFilePath: comparingFilePath || '',
    comparingFilePathSearchValue: comparingFileName || '',
    comparingFilePathObjects: [],

    reports: task.reports || ['manifest-report', 'info-types-report', 'elements-information-report'],

    createFolders: false,
  };
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

function CreatePandocDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [goToLastStep] = useState(Object.keys(rerunTask).length > 4);

  const [checkingOutputFile, setCheckingOutputFile] = useState(false);
  const [outputFileWarning, setOutputFileWarning] = useState(undefined);

  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);
  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingComparingFileObjects, setLoadingComparingFileObjects] = useState(false);

  let loading = false;

  if (goToLastStep) {
    loading = [
      checkingOutputFile,
      loadingSourceFileObjects,
      loadingOutputFolderObjects,
      loadingComparingFileObjects,
    ].some(v => v === true);
  }

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
    if (values.outputFolderPathSearchValue !== '') {
      outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
    }

    const task = {
      tag: 'latest',
      engine: TASK_ENGINE_PANDOC,
      converter: `${values.readFormat}2${values.writeFormat}`,
      source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
      output: `${outputFolder}${values.outputFilename}`,
      reports: values.reports,
    };

    if (values.comparingFilePatsh) {
      task.expected_result = `${values.comparingFilePath}${values.comparingFilePath.endsWith('/') ? '' : '/'}${values.comparingFilePathSearchValue}`;
    }

    dispatch(createPandocTask(task, values.env))
      .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
      .catch(() => setCreatingTask(false));
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  useEffect(() => {
    if (open) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [formik.values.sourceFilePath, open]);

  useEffect(() => {
    if (open) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [formik.values.outputFolderPath, open]);

  useEffect(() => {
    if (open) {
      setLoadingComparingFileObjects(true);

      getObjectsFromPath(`/${formik.values.comparingFilePath}`)
        .then(objects => {
          formik.setFieldValue('comparingFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('comparingFilePath').then(() => setLoadingComparingFileObjects(false));
            });
        });
    }
  }, [formik.values.comparingFilePath, open]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename, open]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let allowCreateFolders = false;
  if (formik.errors.outputFolderPath !== undefined) {
    if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
      allowCreateFolders = true;
    }
  }

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const config = {
    title: 'Create Pandoc',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [
          {
            item: key => <MenuItem key={`read-${key}`} value={key}>{key}</MenuItem>,
            onChange: event => formik.handleChange(event),
            value: formik.values.readFormat,
            error: formik.errors.readFormat,
            items: READ_FORMAT_OPTIONS,
            renderValue: value => {
              if (value === '') return <Placeholder>Read format</Placeholder>;

              return value;
            },
            label: 'Read format',
            key: 'readFormat',
          },
          {
            item: key => <MenuItem key={`write-${key}`} value={key}>{key}</MenuItem>,
            onChange: event => formik.handleChange(event),
            value: formik.values.writeFormat,
            error: formik.errors.writeFormat,
            items: WRITE_FORMAT_OPTIONS,
            renderValue: value => {
              if (value === '') return <Placeholder>Write format</Placeholder>;

              return value;
            },
            label: 'Write format',
            key: 'writeFormat',
          },
          {
            item: report => (
              <MenuItem key={report.key} value={report.key}>
                <Checkbox
                  checked={formik.values.reports.indexOf(report.key) > -1}
                  style={{ padding: '0 9px 0 0' }}
                />
                <ListItemText primary={report.name} />
              </MenuItem>
            ),
            onChange: event => {
              const { value } = event.target;
              if (Array.isArray(value) && value[value.length - 1] === 'all') {
                setTimeout(
                  () => {
                    const currentValue = formik.values.reports;

                    let listValue = AUTOMATIC_REPORTS_MAPS.map(r => r.key);
                    if (
                      currentValue.length === AUTOMATIC_REPORTS_MAPS.length
                      || (currentValue.length !== 0
                        && currentValue.length !== AUTOMATIC_REPORTS_MAPS.length)
                    ) {
                      listValue = [];
                    }

                    formik.setFieldValue('reports', [...listValue]);
                  },
                  0,
                );
                return;
              }

              formik.handleChange(event);
            },
            multiple: true,
            enableSelectAll: true,
            value: formik.values.reports,
            error: formik.errors.reports,
            renderValue: value => {
              if (value.length === 0) return <Placeholder>Automatic reports</Placeholder>;

              if (Array.isArray(value)) {
                const displayValue = [];
                AUTOMATIC_REPORTS_MAPS.forEach(report => {
                  if (formik.values.reports.includes(report.key)) {
                    displayValue.push(report.name);
                  }
                });

                return displayValue.join(', ');
              }

              return value;
            },
            items: AUTOMATIC_REPORTS_MAPS,
            placeholder: 'Automatic reports',
            label: 'Automatic reports',
            key: 'reports',
          },
        ],
        allowContinue: formik.values.readFormat !== '' && formik.values.writeFormat !== '',
        isValid: (formik.values.readFormat !== '' && formik.errors.readFormat === undefined)
          && (formik.values.writeFormat !== '' && formik.errors.writeFormat === undefined),
        onSubmit: () => Promise.resolve(true),
        loading: false,
      },
      {
        title: 'Source file path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
          value: formik.values.sourceFilePath !== ''
            ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
            : formik.values.sourceFilePathSearchValue,
          error: formik.errors.sourceFilePath,
          loading: false,
        },
        allowContinue: formik.values.sourceFilePath !== '',
        isValid: formik.errors.sourceFilePath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFileObjects,
      },
      {
        title: 'Output file path',
        type: STEP_FIELD_TYPE_PATH,
        displayValue: `${outputFolder}${formik.values.outputFilename}`,
        displayNode: (
          <span
            dangerouslySetInnerHTML={{__html: `${outputFolder.replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}<b>${formik.values.outputFilename.replaceAll(' ', '&nbsp;')}</b>`}}  // eslint-disable-line
          />
        ),
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.outputFilename,
            error: formik.errors.outputFilename,
            placeholder: 'Example.zip',
            label: 'Output file name',
            key: 'outputFilename',
          },
        ],
        pathField: {
          label: 'Output folder path',
          placeholder: 'Search a folder by name',
          objects: filterObjects(
            formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
          ).filter(o => o.type === 'folder'),
          onChange: value => {
            onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
            formik.setFieldValue('createFolders', false);
          },
          value: formik.values.outputFolderPath !== ''
            ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
            : formik.values.outputFolderPathSearchValue,
        },
        configurations: allowCreateFolders ? [
          {
            tooltip: (
              <p>
                <p>
                  The output folder does not exist. If you select this parameter,
                  the folder will be created automatically.
                </p>
                <p>
                  <b>Notice. </b>
                  Use this parameter carefully.
                </p>
                <p>
                  THE PARENT FOLDER MUST EXIST.
                </p>
              </p>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.createFolders,
            label: 'Create Folders',
            key: 'createFolders',
          },
        ] : [],
        allowContinue: formik.values.outputFolderPath !== ''
          && (formik.errors.outputFolderPath === undefined || formik.values.createFolders)
          && formik.values.outputFilename !== '',
        isValid: formik.values.outputFolderPath !== ''
          && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
        onSubmit: () => Promise.resolve(true),
        error: formik.errors.outputFolderPath,
        loading: loadingOutputFolderObjects,
        warning: outputFileWarning,
      },
      {
        title: 'Comparing file (optional)',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.comparingFilePathObjects, formik.values.comparingFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'comparingFilePath', 'comparingFilePathSearchValue'),
          value: formik.values.comparingFilePath !== ''
            ? `${formik.values.comparingFilePath}${formik.values.comparingFilePath.endsWith('/') ? '' : '/'}${formik.values.comparingFilePathSearchValue}`
            : formik.values.comparingFilePathSearchValue,
          error: formik.errors.comparingFilePath,
        },
        isValid: (formik.values.comparingFilePathSearchValue !== '' || formik.values.comparingFilePath !== '')
          ? formik.errors.comparingFilePath === undefined : true,
        onSubmit: () => Promise.resolve(true),
        loading: loadingComparingFileObjects,
        allowContinue: true,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    seeValuesAfterActiveStep: true,
    allowAnyStepSelection: true,
    loading: creatingTask,
    initActiveStep: 0,
  };

  useEffect(() => {
    setCheckingOutputFile(true);

    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);

      setCheckingOutputFile(false);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined))
        .finally(() => setCheckingOutputFile(false));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  if (goToLastStep) config.initActiveStep = config.steps.length;

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      loading={loading}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
    />
  );
}

CreatePandocDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreatePandocDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreatePandocDialog;
