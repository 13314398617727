import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Grid,
  Divider,
} from '@material-ui/core';
import LoadingButton from '../common/LoadingButton';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { MAIN_UI_COLOR } from '../../config';
import {
  getUploadPartUrl,
  uploadPartToURL,
  createMultipartUpload,
  completeMultipartUpload,
  abortMultipartUpload,
} from '../../redux/actions/fileStorageActions';
import { formatBytes, normalizeFilename } from '../common/Utility';
import { showErrorSnackbar } from '../../redux/actions/appActions';

const CHUNK_SIZE = 2 * 1000 * 1000 * 1000; // GB * MB * KB * B;

function UploadLargeFileDialog(props) {
  const {
    open, onClose, dirPath, onSubmit,
  } = props;
  const dispatch = useDispatch();

  const [intervalId, setIntervalId] = useState(undefined);
  const [uploadParts, setUploadParts] = useState({});

  const [multipartUpload, setMultipartUpload] = useState(undefined);

  const [uploadFile, setUploadFile] = useState(undefined);

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  function onCompleteMultipartUpload() {
    if (multipartUpload !== undefined) {
      const normalizedName = normalizeFilename(uploadFile.name);
      const filepath = `${dirPath}/${normalizedName}`;

      completeMultipartUpload(filepath, multipartUpload.uploadId)
        .then(() => {
          setUploading(false);
          setUploaded(true);
        })
        .catch(() => {});
    }
  }

  function onAbortMultipartUpload() {
    setUploading(false);
  }

  useEffect(() => {
    const values = Object.values(uploadParts);

    if (!uploaded) {
      if (values.length > 0 && values.every(_ => _.uploaded === true)) {
        clearInterval(intervalId);

        onCompleteMultipartUpload();
      }
    }
  }, [uploadParts]);

  useEffect(() => {
    if (multipartUpload !== undefined && (!uploaded && !uploading)) {
      const normalizedName = normalizeFilename(uploadFile.name);
      const filepath = `${dirPath}/${normalizedName}`;

      abortMultipartUpload(filepath, multipartUpload.uploadId)
        .catch(() => {});
    }
  }, [uploading]);

  function onClickUpload() {
    if (uploadFile !== undefined) {
      const uploadPartsState = {};

      const totalChunks = Math.ceil(uploadFile.size / CHUNK_SIZE);
      const normalizedName = normalizeFilename(uploadFile.name);

      setUploading(true);

      createMultipartUpload(`${dirPath}/${normalizedName}`)
        .then(multipartUploadObject => {
          setMultipartUpload(multipartUploadObject);

          for (let chunkNumber = 1; chunkNumber < totalChunks + 1; chunkNumber += 1) {
            const start = (chunkNumber - 1) * CHUNK_SIZE;
            const chunk = uploadFile.slice(start, Math.min(start + CHUNK_SIZE, uploadFile.size));

            getUploadPartUrl(
              multipartUploadObject.path,
              multipartUploadObject.uploadId,
              chunkNumber,
            )
              .then(uploadPartUrl => {
                uploadPartToURL(uploadPartUrl, chunk, e => {
                  const progressPercentage = ((e.loaded / e.total) * 100).toFixed(1);

                  uploadPartsState[chunkNumber] = {
                    progressPercentage,
                    loaded: e.loaded,
                    uploaded: false,
                    total: e.total,
                  };
                })
                  .then(() => {
                    uploadPartsState[chunkNumber] = {
                      loaded: uploadPartsState[chunkNumber].total,
                      total: uploadPartsState[chunkNumber].total,
                      progressPercentage: '100',
                      uploaded: true,
                    };
                  })
                  .catch(() => onAbortMultipartUpload());
              })
              .catch(() => onAbortMultipartUpload());
          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            error.response.data.errors.forEach(err => dispatch(showErrorSnackbar(err.detail)));
          }

          onAbortMultipartUpload();
        });

      const interval = window.setInterval(
        () => setUploadParts({ ...uploadPartsState }),
        2000,
      );
      setIntervalId(interval);
    }
  }

  function closeDialog() {
    if (uploaded) {
      onSubmit();
    }

    onClose();
  }

  window.addEventListener('beforeunload', () => {
    if (multipartUpload !== undefined && (!uploaded && !uploading)) {
      const normalizedName = normalizeFilename(uploadFile.name);
      const filepath = `${dirPath}/${normalizedName}`;

      abortMultipartUpload(filepath, multipartUpload.uploadId)
        .catch(() => {});
    }
  });

  let loadedTotal = 0;
  let loadedCurrent = 0;
  let totalLoadedPercentage = 0;

  if (Object.keys(uploadParts).length > 0) {
    Object.values(uploadParts).forEach(v => {
      loadedCurrent += v.loaded;
      loadedTotal += v.total;
    });

    totalLoadedPercentage = ((loadedCurrent / loadedTotal) * 100).toFixed(1);
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <RyffineDialogTitle onClose={() => closeDialog()}>
        Upload Large File
      </RyffineDialogTitle>

      <DialogContent style={{ padding: '12px 24px 24px 24px' }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography style={{ fontSize: '16px', fontWeight: 500 }}>
              Select large file:
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              disabled={uploading}
              onChange={e => setUploadFile(e.target.files[0])}
              style={{ width: '100%' }}
              variant="standard"
              color="primary"
              type="file"
            />
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'justify' }}>
            {Object.keys(uploadParts).length > 0 && (
              <Grid container spacing={1}>
                {uploading && (
                  <Grid item xs={12}>
                    <b>Notice.</b>
                    {' '}
                    You can close this dialog without reloading or going anywhere from this page.
                    {' '}
                    This will interrupt the upload process.
                  </Grid>
                )}

                {Object.keys(uploadParts).length > 1 && (
                  <>
                    <Grid item xs={12}>
                      The upload file has been splitted to
                      {' '}
                      <b>{Object.keys(uploadParts).length}</b>
                      {' '}
                      upload parts:
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    {Object.entries(uploadParts).map(([key, data]) => (
                      <>
                        <Grid item xs={6}>
                          <b>
                            Part
                            {' '}
                            {key}
                          </b>
                          : upload
                          {' '}
                          {data.progressPercentage}
                          {' '}
                          of 100 %
                          (
                          {formatBytes(data.loaded)}
                          /
                          {formatBytes(data.total)}
                          )
                        </Grid>
                      </>
                    ))}

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <b>Total</b>
                  : upload
                  {' '}
                  {totalLoadedPercentage}
                  {' '}
                  of 100 % (
                  {formatBytes(loadedCurrent)}
                  /
                  {formatBytes(loadedTotal)}
                  )
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions style={{ padding: 0 }}>
        <Grid
          container
          style={{
            padding: '16px 24px 16px 24px',
            background: MAIN_UI_COLOR,
          }}
        >
          <Grid item xs={12}>
            <LoadingButton
              loading={uploading}
              onClick={() => onClickUpload()}
              disabled={uploadFile === undefined || uploading}
              className="button create-task-button"
              style={{
                background: 'rgb(248, 248, 248)',
                minWidth: '90px',
              }}
              variant="outlined"
              color="primary"
            >
              Upload
            </LoadingButton>

            <Button
              style={{ marginLeft: '10px', color: '#ffffff' }}
              onClick={() => closeDialog()}
              className="button"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

UploadLargeFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dirPath: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UploadLargeFileDialog;
