import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 50,
  },
  title: {
    fontWeight: 'bold',
    '&.title-variant-primary > :first-child': {
      color: theme.palette.primary.main,
    },
    '&.title-variant-secondary > :last-child': {
      color: theme.palette.primary.main,
    },
  },
  bar: {
    height: 50,
    borderRadius: 15,
    position: 'relative',
    background: 'linear-gradient(90deg, rgba(210,51,51,1) 0%, rgba(221,212,27,1) 50%, rgba(65,143,74,1) 100%)',
  },
  tick: {
    color: '#3e3e3e',
    position: 'absolute',
    top: 1,
    '& > svg': {
      fontSize: 84,
    },
  },
  scaleItem: {
    textAlign: 'center',
    '& > *': {
      fontWeight: 'bold',
      lineHeight: 1,
    },
  },
}));

const scaleItemColors = ['#d23333', '#d88427', '#ddd41b', '#8fb233', '#418f4a']; // the same as in gradient

function ScoreBar(props) {
  const { score, maxScore } = props;
  const classes = useStyles();
  const scoreValue = (score * 100) / maxScore;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography className={clsx(classes.title, `title-variant-${props.titleVariant}`)} variant="h4">
          <span>
            {props.title}
            :
          </span>
          {' '}
          <span>
            {score}
            /
            {maxScore}
          </span>
        </Typography>
      </Grid>
      <Grid className="pt-4" item xs={12}>
        <div className={classes.bar}>
          <span className={classes.tick} style={{ left: `calc(${scoreValue}% - 42px)` }}>
            <ArrowDropUpIcon />
          </span>
        </div>
        <Grid className="my-3" container spacing={3} justifyContent="space-around" alignItems="center">
          {props.scale.map((item, index) => (
            <Grid
              className={classes.scaleItem}
              key={item.score}
              item
              xs
              style={{ color: scaleItemColors[index] }}
            >
              <Typography variant="subtitle2">
                {item.title}
              </Typography>
              <Typography variant="subtitle1">
                {item.score}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

ScoreBar.defaultProps = {
  titleVariant: 'primary',
};

ScoreBar.propTypes = {
  maxScore: PropTypes.number.isRequired,
  scale: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  score: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.oneOf(['primary', 'secondary']),
};

export default ScoreBar;
