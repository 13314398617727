import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { RiFileTextLine } from 'react-icons/ri';
import { MdDeleteForever, MdOutlineWarning } from 'react-icons/md';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import RyffineDialogTitle from '../common/RyffineDialogTitle';
import { showErrorSnackbar, showSuccessSnackbar } from '../../redux/actions/appActions';
import { uploadFile } from '../../redux/actions/fileStorageActions';
import HtmlTooltip from '../common/HtmlTooltip';
import { normalizeFilename } from '../common/Utility';

const HeadTableCell = withStyles({
  root: {
    padding: '6px 12px 6px 0px', fontFamily: 'Roboto', fontSize: '16px', fontWeight: 500,
  },
})(TableCell);
const IconTableCell = withStyles(
  { root: { padding: '6px 4px 6px 0px', width: '21px !important', borderBottom: 'none' } },
)(TableCell);
const RowTableCell = withStyles(
  { root: { padding: '6px 12px 6px 0px', borderBottom: 'none' } },
)(TableCell);

const useStyles = makeStyles(theme => ({
  dropContainerBorder: {
    background: 'url("data:image/svg+xml,%3csvg width=\'100%25\' height=\'100%25\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'100%25\' height=\'100%25\' fill=\'none\' rx=\'4\' ry=\'4\' stroke=\'%2333333380\' stroke-width=\'4\' stroke-dasharray=\'10%2c 15\' stroke-dashoffset=\'9\' stroke-linecap=\'square\'/%3e%3c/svg%3e")',
    borderRadius: '4px',
  },
  dropContainer: {
    backgroundColor: 'rgba(51, 51, 51, 0.05)',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 3,
    height: 400,
  },
}));

const getRejectedFileErrors = rejectedFiles => {
  const errors = rejectedFiles.reduce((acc, rejectedFile) => {
    rejectedFile.errors.forEach(err => {
      acc[err.code] = err.message;
    });
    return acc;
  }, {});

  return Object.values(errors);
};

const useUploadingFileStatus = (
  index, uploadFileIndex, warningMessage, failedIndexes, deleteFile,
) => {
  if (index > uploadFileIndex) {
    if (warningMessage) {
      return [
        <>
          <HtmlTooltip title={warningMessage}>
            <IconButton className="outlined-button-icon">
              <MdOutlineWarning size="16px" />
            </IconButton>
          </HtmlTooltip>
          Warning
        </>,
        <IconButton>
          <MdDeleteForever size={20} color="#EB5757" onClick={deleteFile} />
        </IconButton>,
      ];
    }

    return [
      'Pending',
      <IconButton>
        <MdDeleteForever size={20} color="#EB5757" onClick={deleteFile} />
      </IconButton>,
    ];
  }

  if (index === uploadFileIndex) {
    return ['Uploading', <CircularProgress size={20} />];
  }

  if (failedIndexes.includes(index)) {
    return ['Failed', <ErrorIcon color="secondary" />];
  }

  return ['Proceeded', <CheckCircleTwoToneIcon color="primary" />];
};

function UploadingFileStatus({
  index, uploadFileIndex, warningMessage, failedIndexes, deleteFile,
}) {
  const [status, image] = useUploadingFileStatus(
    index, uploadFileIndex, warningMessage, failedIndexes, deleteFile,
  );

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>{status}</Grid>
      <Grid>{image}</Grid>
    </Grid>
  );
}

UploadingFileStatus.defaultProps = {
  warningMessage: undefined,
};

UploadingFileStatus.propTypes = {
  warningMessage: PropTypes.string,
  index: PropTypes.number.isRequired,
  uploadFileIndex: PropTypes.number.isRequired,
  failedIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  deleteFile: PropTypes.func.isRequired,
};

function UploadFilesDialog(props) {
  const {
    open, onClose, dirPath, onSubmit,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [failedFileIndexes, setFailedFileIndexes] = useState([]);

  const [uploadFileIndex, setUploadFileIndex] = useState(-1);
  const [successMsg, setSuccessMsg] = useState('');

  const uploading = uploadFileIndex > -1 && uploadFileIndex < filesToUpload.length;
  const allFilesUploaded = uploadFileIndex === filesToUpload.length;

  useEffect(() => {
    if (uploading) {
      const file = filesToUpload[uploadFileIndex];

      dispatch(uploadFile(dirPath, file))
        .then(() => setSuccessMsg(`File "${file.path}" has been uploaded to "${dirPath}"`))
        .catch(() => setFailedFileIndexes([...failedFileIndexes, uploadFileIndex]))
        .finally(() => setUploadFileIndex(uploadFileIndex + 1));
    } else if (allFilesUploaded && !open) {
      resetState();
    }
  }, [uploadFileIndex]); // eslint-disable-line

  useEffect(() => {
    if (!open && successMsg) {
      dispatch(showSuccessSnackbar(successMsg));
      setSuccessMsg('');
    }
  }, [successMsg]); // eslint-disable-line

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const errorMessages = getRejectedFileErrors(rejectedFiles);

      errorMessages.forEach(errorMessage => {
        dispatch(showErrorSnackbar(errorMessage));
      });
    }

    if (acceptedFiles.length > 0) {
      setFilesToUpload(files => {
        /* eslint-disable-next-line */
        for (const acceptedFile of acceptedFiles) {
          let alreadyExist = false;

          acceptedFile.normalizedName = normalizeFilename(acceptedFile.name);

          /* eslint-disable-next-line */
          for (const file of files) {
            if (JSON.stringify(file) === JSON.stringify(acceptedFile)) {
              alreadyExist = true;
              break;
            }
          }

          if (!alreadyExist) files.push(acceptedFile);
        }

        return files;
      });

      setUploadFileIndex(-1);
      setFailedFileIndexes([]);
    }
  }, [dispatch]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openSelectFileDialog,
  } = useDropzone({
    onDrop,
    noClick: true,
    disabled: uploading,
  });

  const handleUpload = () => {
    if (filesToUpload.length > 0) {
      setUploadFileIndex(0);
    }
  };

  function closeDialog() {
    if (allFilesUploaded) {
      onSubmit();
    }

    if (!uploading) {
      resetState();
    }

    onClose();
  }

  function resetState() {
    setFilesToUpload([]);
    setFailedFileIndexes([]);
    setUploadFileIndex(-1);
    setSuccessMsg('');
  }

  const deleteFile = index => {
    const array = [...filesToUpload];

    array.splice(index, 1);
    setFilesToUpload(array);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <RyffineDialogTitle onClose={() => closeDialog()}>
        Upload Files
      </RyffineDialogTitle>
      <DialogContent>
        <Grid item xs={12} className={classes.dropContainerBorder}>
          <Grid
            item
            xs={12}
            container
            {...getRootProps()}
            alignContent="center"
            justifyContent="center"
            className={classes.dropContainer}
          >
            <input {...getInputProps()} />

            <Typography variant="h4" color="textSecondary">
              {isDragActive && (
              <span style={{ color: '#333333', fontWeight: '500', fontSize: '28px' }}>
                Drop your files to start uploading
              </span>
              )}
              {!isDragActive && (
              <div style={{ textAlign: 'center' }}>
                <p style={{ color: '#333333', fontWeight: '500', fontSize: '28px' }}>
                  Drag and drop your files here or
                </p>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={uploading}
                  onClick={openSelectFileDialog}
                >
                  Browse computer
                </Button>
                <p style={{ color: '', fontSize: '20px' }}>
                  Uploading is allowed only to buckets
                  {' '}
                  <br />
                  {' '}
                  created in `&apos;us-east-1`&apos; region
                </p>
              </div>
              )}
            </Typography>
          </Grid>
        </Grid>

        {filesToUpload.length > 0 && (
        <Grid container className="my-3">
          <Grid item xs={12}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <HeadTableCell colSpan={2}>Name</HeadTableCell>
                  <HeadTableCell width="100px">Size</HeadTableCell>
                  <HeadTableCell width="150px">Status</HeadTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filesToUpload.map((file, index) => (
                  <TableRow key={file.path}>
                    <IconTableCell><RiFileTextLine size={21} /></IconTableCell>
                    <RowTableCell>{file.path}</RowTableCell>
                    <RowTableCell>{prettyBytes(file.size)}</RowTableCell>
                    <RowTableCell>
                      <UploadingFileStatus
                        index={index}
                        uploadFileIndex={uploadFileIndex}
                        failedIndexes={failedFileIndexes}
                        deleteFile={() => deleteFile(index)}
                        warningMessage={
                          file.name !== file.normalizedName
                            ? (
                              <>
                                <p>
                                  Filename contains the reserved URL characters. During
                                  uploading, the name will be changed automatically to:
                                </p>
                                <p><b>{file.normalizedName}</b></p>
                              </>
                            )
                            : undefined
                        }
                      />
                    </RowTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        )}

        <Grid container justifyContent="flex-end">
          {uploading && (
            <Typography variant="body2" color="textSecondary">
              Feel free to close the dialog. You will be informed when uploading is done.
            </Typography>
          )}
        </Grid>

        <Grid className="mt-2 mb-4" container justifyContent="flex-start" alignItems="center">
          <Button
            disabled={filesToUpload.length === 0 || uploadFileIndex > -1}
            onClick={handleUpload}
            variant="contained"
            className="button"
            color="primary"
          >
            Upload
          </Button>

          <Button
            onClick={() => closeDialog()}
            style={{ marginLeft: '16px' }}
            className="button"
            color="primary"
          >
            {uploadFileIndex > -1 ? 'Close' : 'Cancel'}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

UploadFilesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dirPath: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UploadFilesDialog;
