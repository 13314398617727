import {
  FETCH_EVALUATION_SCORE_START,
  FETCH_EVALUATION_SCORE_SUCCESS,
  FETCH_EVALUATION_SCORE_FAIL,
  FETCH_EVALUATION_PDF_START,
  FETCH_EVALUATION_PDF_SUCCESS,
  FETCH_EVALUATION_PDF_FAIL,
  FETCH_EVALUATION_HTML_START,
  FETCH_EVALUATION_HTML_SUCCESS,
  FETCH_EVALUATION_HTML_FAIL,
} from '../actionTypes';

const initialState = {
  json: {
    friendlyName: '',
    validFiles: [],
    loading: false,
    rootMaps: {},
    scores: [],
  },
  pdf: {
    blob: undefined,
    loading: false,
  },
  html: {
    blob: undefined,
    loading: false,
  },
};

export default function evaluationReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EVALUATION_SCORE_START:
      return {
        ...state,
        json: {
          friendlyName: '',
          validFiles: [],
          loading: true,
          rootMaps: {},
          scores: [],
        },
      };

    case FETCH_EVALUATION_SCORE_SUCCESS:
      return {
        ...state,
        json: {
          friendlyName: action.payload.friendly_name,
          validFiles: action.payload.valid_files,
          rootMaps: action.payload.root_maps,
          scores: action.payload.scores,
          loading: false,
        },
      };

    case FETCH_EVALUATION_SCORE_FAIL:
      return {
        ...state,
        json: {
          friendlyName: '',
          validFiles: [],
          loading: false,
          rootMaps: {},
          scores: [],
        },
      };

    case FETCH_EVALUATION_PDF_START:
      return {
        ...state,
        pdf: {
          blob: undefined,
          loading: true,
        },
      };

    case FETCH_EVALUATION_PDF_SUCCESS:
      return {
        ...state,
        pdf: {
          blob: action.payload,
          loading: false,
        },
      };

    case FETCH_EVALUATION_PDF_FAIL:
      return {
        ...state,
        pdf: {
          blob: undefined,
          loading: true,
        },
      };

    case FETCH_EVALUATION_HTML_START:
      return {
        ...state,
        html: {
          blob: undefined,
          loading: true,
        },
      };

    case FETCH_EVALUATION_HTML_SUCCESS:
      return {
        ...state,
        html: {
          blob: action.payload,
          loading: false,
        },
      };

    case FETCH_EVALUATION_HTML_FAIL:
      return {
        ...state,
        html: {
          blob: undefined,
          loading: true,
        },
      };

    default:
      return state;
  }
}
