import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Button,
  Snackbar,
  IconButton,
  Typography,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Paper,
  Grow,
} from '@material-ui/core';
import { FiLink2 } from 'react-icons/fi';
import { AiOutlineMenu } from 'react-icons/ai';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { IoMdRefresh } from 'react-icons/io';
import { FaFilePdf, FaHtml5 } from 'react-icons/fa';
import { MdOutlineSaveAlt } from 'react-icons/md';
import { saveAs } from 'file-saver';
import { getTask, getTaskState } from '../../redux/actions/taskActions';
import { MENU_CLOSE, MENU_OPEN } from '../../redux/actionTypes';
import Loader from '../common/Loader';
import NotFound from '../common/NotFound';
import { TASKS_ROUTE } from '../../core/constants';
import {
  TASK_ENGINE_XSLT,
  TASK_ENGINE_PYTHON,
  TASK_ENGINE_PANDOC,
  TASK_ENGINE_OXYGEN,
  TASK_STATUS_FINISHED,
} from '../../core/entities';
import { isObjectEmpty } from '../../core/services/commonService';
import TaskDetailsInfo from './TaskDetailsInfo';
import TaskDetailsLogs from './TaskDetailsLogs';
import TaskDetailsReports from './TaskDetailsReports';
import TaskDetailsReportLogs from './TaskDetailsReportLogs';
import HashedTabs from '../common/HashedTabs';
import { MAIN_UI_COLOR } from '../../config';
import TaskDetailsErrors from './TaskDetailsErrors';
import TaskDetailsWarnings from './TaskDetailsWarnings';
import { useQuery } from '../common/Utility';

function getTaskReportConfig(task) {
  const reports = [];

  if (
    (task.reports && task.reports.length > 0)
    || [
      'conditional-attributes-report',
      'elements-information-report',
      'not-referenced-ids-report',
      'resourceid-mapping-report',
      'required-cleanup-report',
      'conref-targets-report',
      'ph-id-content-report',
      'info-types-report',
      'manifest-report',
    ].includes(task.converter)
  ) {
    if (task.reports.includes('elements-information-report') || task.converter === 'elements-information-report') {
      reports.push(
        {
          name: 'elements-information-general',
          label: 'Elements Information - General Report',
        },
      );
    }

    if (task.reports.includes('ids-not-referenced-report') || task.converter === 'not-referenced-ids-report') {
      reports.push(
        {
          name: 'not-referenced-ids-general',
          label: 'Not Referenced IDs - General Report',
        },
      );
    }

    if (task.reports.includes('required-cleanup-report') || task.converter === 'required-cleanup-report') {
      reports.push({
        name: 'required-cleanup-general',
        label: 'Required-Cleanup - General Report',
      });
    }

    if (task.reports.includes('conref-targets-report') || task.converter === 'conref-targets-report') {
      reports.push(
        {
          name: 'conref-targets-general',
          label: 'Conref Targets - General Report',
        },
      );

      reports.push(
        {
          name: 'conref-targets-detailed',
          label: 'Conref Targets - Detailed Report',
        },
      );
    }

    if (task.reports.includes('ph-id-content-report') || task.converter === 'ph-id-content-report') {
      reports.push(
        {
          name: 'ph-id-content-general',
          label: 'Ph ID Content - General Report',
        },
      );

      reports.push(
        {
          name: 'ph-id-content-detailed',
          label: 'Ph ID Content - Detailed Report',
        },
      );
    }

    if (task.reports.includes('info-types-report') || task.converter === 'info-types-report') {
      reports.push(
        {
          name: 'info-types-general',
          label: 'Info Types - General Report',
        },
      );

      reports.push(
        {
          name: 'info-types-detailed',
          label: 'Info Types - Detailed Report',
        },
      );
    }

    if (task.reports.includes('manifest-report') || task.converter === 'manifest-report') {
      reports.push({
        name: 'manifest-general',
        label: 'Manifest - General Report',
      });

      reports.push({
        name: 'manifest-found-files',
        label: 'Manifest - Found Files Report',
      });

      reports.push({
        name: 'manifest-files-with-invalid-names',
        label: 'Manifest - Files With Invalid Names',
      });

      reports.push({
        name: 'manifest-duplicate-files',
        label: 'Manifest - Duplicate Files',
      });
    }

    if (task.reports.includes('resourceid-mapping-report') || task.converter === 'resourceid-mapping-report') {
      reports.push({
        name: 'resourceid-mapping-general',
        label: 'Resource ID Mapping - General Report',
      });
    }

    if (task.reports.includes('conditional-attributes-report') || task.converter === 'conditional-attributes-report') {
      reports.push({
        name: 'conditional-attributes-general',
        label: 'Conditional Attributes - General Report',
      });
    }
  }

  if (['evaluation-estimate'].includes(task.converter)) {
    reports.push(
      {
        name: 'general_report',
        label: 'General Estimate Report',
      },
    );

    reports.push(
      {
        name: 'following_simplified_technical_english',
        label: 'Following Simplified Technical English Score',
      },
    );

    reports.push(
      {
        name: 'following_titling_conventions',
        label: 'Following Titling Conventions Score',
      },
    );

    reports.push(
      {
        name: 'consistent_sentence_structure',
        label: 'Consistent Sentence Structure Score',
      },
    );

    reports.push(
      {
        name: 'punctuation_consistency',
        label: 'Punctuation Consistency Score',
      },
    );

    reports.push(
      {
        name: 'list_consistency',
        label: 'List Consistency Score',
      },
    );
  }

  if (['count-of-docx-pages-report'].includes(task.converter)) {
    reports.push(
      {
        name: 'general',
        label: 'General Report',
      },
    );
  }

  if (task.converter === 'reuse-report') {
    reports.push(
      {
        name: 'reuse_mechanism_counts',
        label: 'Reuse Mechanism Counts',
      },
    );

    reports.push(
      {
        name: 'reuse_mechanism_files',
        label: 'Reuse Mechanism Files',
      },
    );
    reports.push(
      {
        name: 'reuse_mechanism_summary',
        label: 'Reuse Mechanism Summary',
      },
    );

    reports.push(
      {
        name: 'reuse_report_exact_matches',
        label: 'Reuse Exact Matches',
      },
    );

    reports.push(
      {
        name: 'reuse_report_fuzzy_matches',
        label: 'Reuse Fuzzy Matches',
      },
    );

    reports.push(
      {
        name: 'reuse_report_matching_summary',
        label: 'Reuse Matching Summary',
      },
    );

    reports.push(
      {
        name: 'reuse_report_processing_summary',
        label: 'Reuse Processing Summary',
      },
    );
  }

  if (task.converter === 'ixiasoft-export-validator') {
    reports.push({
      name: 'versions_summary',
      label: 'Versions',
    });

    reports.push({
      name: 'errors_summary',
      label: 'Errors',
    });
  }

  if (task.converter === 'root-maps-report') {
    reports.push({
      name: 'root_maps_report',
      label: 'Root Maps Report',
    });
  }

  if (task.expectedResult) {
    reports.push({
      name: 'diff',
      label: 'Difference',
    });
  }

  if (
    [
      TASK_ENGINE_XSLT,
      TASK_ENGINE_PYTHON,
      TASK_ENGINE_PANDOC,
      TASK_ENGINE_OXYGEN,
    ].includes(task.engine)
  ) {
    reports.push({
      name: 'converter_summary_report',
      label: 'Converter Summary - General Report',
    });

    reports.push({
      name: 'converter_count_words_report',
      label: 'Converter Summary - Count Words Report',
    });

    reports.push({
      name: 'converter_count_characters_report',
      label: 'Converter Summary - Count Characters Report',
    });

    reports.push({
      name: 'converter_images_report',
      label: 'Converter Summary - Images Reports',
    });

    reports.push({
      name: 'converter_summary_list_files_output',
      label: 'Converter Summary - Output Files',
    });
  }

  if (reports.length === 0) {
    return {};
  }

  return {
    default: reports[0].name,
    reports,
  };
}

function TaskDetails() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const query = useQuery();

  const [task, setTask] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [hasErrors, setHasErrors] = useState(false);
  const [hasWarnings, setHasWarnings] = useState(false);

  const isMenuOpen = useSelector(state => state.app.isMenuOpen);
  const isMobile = useSelector(state => state.app.isMobile);

  const isReportsTabOn = !isObjectEmpty(task) && task.converter === 'evaluation' && task.status === TASK_STATUS_FINISHED;

  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);

  const [openSaveAsMenu, setOpenSaveAsMenu] = useState(false);
  const saveAsAnchorRef = useRef(null);

  const pdfReport = useSelector(state => state.evaluation.pdf);
  const htmlReport = useSelector(state => state.evaluation.html);

  useEffect(() => {
    let timer;

    getTask(id)
      .then(taskEntity => {
        if (!taskEntity) setError(true);
        else {
          setTask(taskEntity);

          timer = setInterval(() => {
            getTask(id)
              .then(newTask => {
                if (JSON.stringify(task) !== JSON.stringify(newTask)) {
                  setTask(newTask);
                }

                if (newTask.status === 'FINISHED') {
                  clearInterval(timer);
                }
              });
          }, 3000);
        }
      })
      .catch(() => setError(true));

    return () => timer && clearInterval(timer);
  }, [id]);

  const onRefresh = () => {
    setLoading(true);

    getTask(id)
      .then(taskEntity => {
        if (taskEntity) setTask(taskEntity);
        else setError(true);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  useEffect(() => onRefresh(), [id]);

  useEffect(() => {
    if (task.status === TASK_STATUS_FINISHED) {
      getTaskState(id)
        .then(taskStateEntity => {
          if (taskStateEntity.hasErrors) setHasErrors(true);
          if (taskStateEntity.hasWarnings) setHasWarnings(true);
        })
        .catch(() => { });
    } else {
      setHasWarnings(false);
      setHasErrors(false);
    }
  }, [task.status]);

  if (error) {
    return <NotFound />;
  }

  if (loading || isObjectEmpty(task)) {
    return <Loader />;
  }

  const tabs = [
    { key: 'details', label: 'Details', component: <TaskDetailsInfo task={task} hasErrors={hasErrors} hasWarnings={hasWarnings} /> },
  ];

  if (hasWarnings) {
    tabs.push({ key: 'warnings', label: 'Warnings', component: <TaskDetailsWarnings task={task} /> });
  }

  if (hasErrors) {
    tabs.push({ key: 'errors', label: 'Errors', component: <TaskDetailsErrors task={task} /> });
  }

  tabs.push({ key: 'logs', label: 'Logs', component: <TaskDetailsLogs task={task} /> });

  if (isReportsTabOn) {
    tabs.push({ key: 'evaluation-report', label: 'Evaluation Report', component: <TaskDetailsReports task={task} /> });
  }

  const taskReportLogs = getTaskReportConfig(task);

  if (task.status === TASK_STATUS_FINISHED && !isObjectEmpty(taskReportLogs)) {
    tabs.push({ key: 'report-logs', label: 'Report', component: <TaskDetailsReportLogs task={task} config={taskReportLogs} /> });
  }

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopySnackbarOpen(true);
  };

  const handleSaveAsPDFClick = () => {
    if (pdfReport && pdfReport.blob) saveAs(pdfReport.blob, `${id}.pdf`);
  };

  const handleSaveAsHTMLClick = () => {
    if (htmlReport && htmlReport.blob) saveAs(htmlReport.blob, `${id}.zip`);
  };

  return (
    <>
      <div style={{
        paddingBottom: '30px',
        paddingRight: '60px',
        paddingLeft: '60px',
        paddingTop: '48px',
        background: 'white',
      }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              variant="h4"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontWeight: 500,
                lineHeight: 1,
              }}
            >
              {(!isMenuOpen || isMobile) && (
              <IconButton
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
                onClick={() => dispatch({ type: !isMenuOpen ? MENU_OPEN : MENU_CLOSE })}
              >
                <AiOutlineMenu />
              </IconButton>
              )}

              <IconButton
                onClick={() => history.push(TASKS_ROUTE)}
                style={{
                  fontSize: '2.125rem',
                  color: MAIN_UI_COLOR,
                  marginRight: '12px',
                  padding: 0,
                }}
              >
                <HiOutlineArrowLeft />
              </IconButton>

              <span style={{ fontSize: '28px' }}>
                {'Task UUID - '}
                {window.location.pathname.split('/').slice(-1).pop()}
              </span>
            </Typography>
          </Grid>

          <Grid className="buttons-container" container item xs={12} sm={12} md={4} justifyContent="flex-end">
            {query.get('tab') === 'evaluation-report' && (
              <>
                <Button
                  onClick={() => setOpenSaveAsMenu(prevOpen => !prevOpen)}
                  style={{ marginLeft: '16px' }}
                  ref={saveAsAnchorRef}
                  variant="contained"
                  className="button"
                  color="primary"
                >
                  <IconButton className="contained-button-icon"><MdOutlineSaveAlt /></IconButton>
                  Save as ...
                </Button>

                <Popper
                  style={{ zIndex: 10 }}
                  anchorEl={saveAsAnchorRef.current}
                  open={openSaveAsMenu}
                  role={undefined}
                  disablePortal
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={() => setOpenSaveAsMenu(false)}>
                          <MenuList key="saveAsList" autoFocusItem={openSaveAsMenu} id="menu-save-as-grow">
                            <MenuItem
                              disabled={pdfReport.blob === undefined && pdfReport.loading}
                              onClick={handleSaveAsPDFClick}
                              key="pdf"
                            >
                              <IconButton className="outlined-button-icon"><FaFilePdf /></IconButton>
                              PDF
                            </MenuItem>

                            <MenuItem
                              disabled={htmlReport.blob === undefined && htmlReport.loading}
                              onClick={handleSaveAsHTMLClick}
                              key="html"
                            >
                              <IconButton className="outlined-button-icon"><FaHtml5 /></IconButton>
                              HTML
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}

            <Button
              onClick={handleCopyButtonClick}
              variant="outlined"
              className="button"
              color="primary"
            >
              <IconButton className="outlined-button-icon"><FiLink2 /></IconButton>
              Copy link
            </Button>

            <Button
              onClick={() => onRefresh()}
              variant="contained"
              className="button"
              color="primary"
            >
              <IconButton className="contained-button-icon"><IoMdRefresh size={18} /></IconButton>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </div>

      <Box maxWidth={1}>
        <HashedTabs tabs={tabs} />
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="The URL link of this task has been copied!"
        onClose={() => setCopySnackbarOpen(false)}
        open={copySnackbarOpen}
      />
    </>
  );
}

export default TaskDetails;
