import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

function DisplayListFiles({ index, summary, data }) {
  const [open, setOpen] = useState(false);

  return (
    <Grid item xs={12}>
      <Grid container justifyContent="center">
        <Grid className="d-flex align-items-center" item xs={10}>
          <Typography
            className="pr-4"
            style={{
              wordWrap: 'break-word',
              width: '100%',
            }}
          >
            <b>
              {index}
              .
            </b>
            {' Root map \''}
            <b>{summary}</b>
            &apos;.
          </Typography>
        </Grid>

        <Grid className="d-flex align-items-center" item xs={2} style={{ display: 'grid', alignItems: 'end' }}>
          <Button
            variant={!open ? 'contained' : 'outlined'}
            style={{ textTransform: 'none' }}
            onClick={() => setOpen(!open)}
            color="primary"
          >
            {open ? 'Close details' : 'View details'}
          </Button>
        </Grid>

        {open && (
          <Grid item xs={12}>
            <List>
              <ListItem style={{ padding: '0 16px' }}>
                <ListItemIcon style={{ minWidth: '24px' }}>
                  <FiberManualRecord color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {'References '}
                    <b>{data.direct_references.maps}</b>
                    {' ditamap(s) and '}
                    <b>{data.direct_references.topics + data.other_references}</b>
                    {' dita file(s).'}
                  </Typography>
                </ListItemText>
              </ListItem>

              {(!Number.isNaN(data.direct_references.topics) && data.direct_references.topics > 0)
                && (
                <ListItem style={{ padding: '0 16px' }}>
                  <ListItemIcon style={{ minWidth: '24px' }}>
                    <FiberManualRecord color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {'The '}
                      <b>{data.direct_references.topics}</b>
                      {' dita file(s) directly referenced by root map and submap(s).'}
                    </Typography>
                  </ListItemText>
                </ListItem>
                )}

              {(!Number.isNaN(data.other_references) && data.other_references > 0) && (
                <ListItem style={{ padding: '0 16px' }}>
                  <ListItemIcon style={{ minWidth: '24px' }}>
                    <FiberManualRecord color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography>
                      {'The '}
                      <b>{data.other_references}</b>
                      {' dita file(s) referenced by other dita file(s).'}
                    </Typography>
                  </ListItemText>
                </ListItem>
              )}

            </List>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

DisplayListFiles.defaultProps = {
  summary: '',
  index: 1,
  data: {},
};

DisplayListFiles.propTypes = {
  summary: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object,
};

export default DisplayListFiles;
