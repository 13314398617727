import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  List,
  Button,
  ListItem,
  ListItemText,
  Typography,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { HiOutlineSearch } from 'react-icons/hi';
import { fetchEvaluationScores } from '../../redux/actions/evaluationActions';
import Loader from '../common/Loader';
import TopLevelCategories from './TopLevelCategories';
import { generateKey, useQuery } from '../common/Utility';
import SubCategories from './SubCategories';
import CategoryScores from './CategoryScores';
import DisplayListFiles from './DisplayListFiles';

function EvaluationReports({ taskId }) {
  const dispatch = useDispatch();
  const query = useQuery();

  const jsonReport = useSelector(state => state.evaluation.json);

  const [displayLength, setDisplayLength] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    dispatch(fetchEvaluationScores(taskId));
  }, [dispatch, taskId]);

  const filteredRootMaps = useMemo(
    () => Object.fromEntries(
      Object.entries(jsonReport.rootMaps).filter(
        ([key]) => key.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    ),
    [jsonReport.rootMaps, searchValue],
  );

  if (jsonReport.loading) {
    return <Loader />;
  }

  const topLevelCategory = query.get('top-level-category');
  if (topLevelCategory) {
    let topLevelConfiguration;

    jsonReport.scores.forEach(score => {
      if (score.name === topLevelCategory) {
        topLevelConfiguration = score;
      }
    });

    if (topLevelConfiguration) {
      const subCategory = query.get('sub-category');

      if (subCategory) {
        let subCategoryConfiguration;

        topLevelConfiguration.sub_scores.forEach(score => {
          if (score.name === subCategory) {
            subCategoryConfiguration = score;
          }
        });

        if (subCategoryConfiguration) {
          return (
            <CategoryScores configuration={subCategoryConfiguration} />
          );
        }
      }

      return (
        <SubCategories configuration={topLevelConfiguration} />
      );
    }
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <TopLevelCategories name={jsonReport.friendlyName} scores={jsonReport.scores} />
      </Grid>

      {jsonReport.rootMaps
        && Object.values(jsonReport.rootMaps).every(v => !Array.isArray(v))
        && Object.keys(jsonReport.rootMaps).length > 0 && (
        <Grid
          item
          xs={10}
          className="mt-4 mb-4"
          style={{
            border: '1px solid rgba(51, 51, 51, 0.3)',
            borderRadius: '8px',
            background: 'white',
          }}
        >
          <div className="p-4">
            <Grid>
              <Typography>
                {'The following '}
                <b>{Object.keys(jsonReport.rootMaps).length}</b>
                {Object.keys(filteredRootMaps).length < Object.keys(jsonReport.rootMaps).length && (
                  <>
                    {' ('}
                    <b>{Object.keys(filteredRootMaps).length}</b>
                    {' filtered)'}
                  </>
                )}
                {' maps were included in this evaluation:'}
              </Typography>
            </Grid>
            <Grid item xs={12} className="pt-4">
              <Box sx={{ boxShadow: 0 }}>
                <TextField
                  onChange={event => setSearchValue(event.target.value)}
                  placeholder="Filter root maps"
                  InputProps={{
                    style: {
                      background: 'rgba(51, 51, 51, 0.05)',
                      paddingBottom: '2.5px',
                      paddingTop: '2.5px',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <HiOutlineSearch style={{ color: 'grey' }} />
                      </InputAdornment>
                    ),
                  }}
                  value={searchValue}
                  variant="outlined"
                  size="small"
                  id="search"
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid>
              <List>
                {Object.entries(filteredRootMaps).slice(0, displayLength).map(([name, data], i) => (
                  <ListItem key={generateKey(name)}>
                    <ListItemText>
                      <DisplayListFiles index={i + 1} summary={name} data={data} />
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid className="buttons-container">
              {(Object.keys(filteredRootMaps).length > 10
              && displayLength < Object.keys(filteredRootMaps).length)
              && (
              <Button
                onClick={() => setDisplayLength(displayLength + 10)}
                style={{ textTransform: 'none' }}
                variant="contained"
                color="primary"
              >
                Load more
              </Button>
              )}
              {displayLength > 10 && (
              <Button
                onClick={() => setDisplayLength(10)}
                style={{ textTransform: 'none' }}
                variant="outlined"
                color="primary"
              >
                Close all
              </Button>
              )}
            </Grid>
          </div>
        </Grid>
      )}

      {/* {validFiles && (
        <ListFiles
          summary={`There are ${validFiles.length} valid DITA files in existence.`}
          files={validFiles}
        />
      )} */}
    </Grid>
  );
}

EvaluationReports.propTypes = {
  taskId: PropTypes.string.isRequired,
};

export default EvaluationReports;
